<script lang="ts" setup>
const props = defineProps<{
  visible: boolean;
}>();
const visible = toRef(props, 'visible');
const emit = defineEmits<{
  (event: 'update:visible', value: boolean): void;
}>();

const { t } = useI18n();
const [modalVisible, onToggleVisible] = useExternalModalState(visible, (v) =>
  emit('update:visible', v),
);
</script>

<template>
  <BaseModal
    :visible="modalVisible"
    @update:visible="onToggleVisible"
    :aria-title="t('aria_title')"
    :aria-describedby="t('aria_description')"
    container-class="productSubscriptionInfoModal"
  >
    <h6 class="productSubscriptionInfoModal__title">{{ t('title') }}</h6>
    <i18n-t
      keypath="content"
      tag="p"
      class="productSubscriptionInfoModal__description"
    >
      <template #discount>{{ SUBSCRIPTION_DISCOUNT_VALUE }}</template>
      <template #link>
        <NuxtLink
          @click="onToggleVisible(false)"
          to="/profile"
          class="productSubscriptionInfoModal__link"
        >
          {{ t('link') }}
        </NuxtLink>
      </template>
    </i18n-t>

    <BaseButton
      @click="onToggleVisible(false)"
      outlined
      dark
      class="productSubscriptionInfoModal__action"
    >
      {{ t('accept') }}
    </BaseButton>
  </BaseModal>
</template>

<i18n>
ru:
  aria_title: Как работает подписка на продукты
  aria_description: Подробная информация о работе подписки на продукты
  title: Как работает подписка
  content: >
    Подписка позволяет регулярно получать рефил любимого средства со скидкой {discount}%.


    Отправка рефила происходит каждые 3 месяца с момента оформления подписки. При оформлении подписки стоимость рефила
    не списывается. Оплата рефила по подписке будет происходить по ссылке, которую мы отправим вам по почте. Это значит,
    что списания не будут происходить автоматически и вы сможете отказаться от рефила, просто проигнорировав письмо со
    ссылкой.


    Подписку нельзя оформить без покупки продукта. Это значит, что при оформлении подписки будет нужно приобрести любой
    продукт: это может быть и флакон (если вы покупаете средство впервые), и рефил (если у вас уже есть флакон). Скидка
    {discount}% при этом применяется только к товару, на который оформлена подписка. Это значит, что первый продукт со
    скидкой вы получите через 3 месяца после оформления подписки.


    Чтобы изменить дату доставки, количество рефилов и другую информацию, свяжитесь с нашим клиентским сервисом.


    Информация о ваших подписках доступна в {link}.
  link: личном кабинете
  accept: Ок, понятно

en:
  aria_title: How subscription works
  aria_description: Detailed information about how subscription works
  title: How subscription works
  content: >
    Subscription allows you to regularly receive a refill of your favorite product with a {discount}% discount.


    The refill is sent every 3 months from the moment of subscription. When subscribing, the cost of the refill is not
    deducted. Payment for the refill by subscription will be made via the link that we will send you by mail. This means
    that deductions will not occur automatically and you can refuse the refill, simply by ignoring the letter with the
    link.


    You cannot subscribe without buying a product. This means that when subscribing, you will need to purchase any
    product: it can be both a bottle (if you are buying the product for the first time), and a refill (if you already
    have the bottle). The {discount}% discount in this case applies only to the product for which the subscription is
    made. This means that you will receive the first product with a discount 3 months after subscribing.


    To change the delivery date, the number of refills and other information, contact our customer service.


    Information about your subscriptions is available in {link}.
  link: personal account
  accept: Ok, understood
</i18n>

<style lang="scss">
.productSubscriptionInfoModal {
  color: #121212;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  max-width: 649px;
  padding: 37px 40px 45px;

  @include mq('sm') {
    padding: 51px 16px 41px;
    display: flex;
    flex-direction: column;
    font-size: 18px;
  }

  &__title {
    font-size: 24px;
    line-height: 32px;
    margin-top: 0;
    margin-bottom: 15px;

    @include mq('sm') {
      text-align: center;
      margin-bottom: 32px;
    }
  }

  &__description {
    margin: 0;
    white-space: pre-wrap;

    @include mq('sm') {
      margin-bottom: auto;
    }
  }

  &__link {
    color: inherit;
    text-decoration: underline;
    text-underline-offset: 4px;
    transition: opacity 0.3s ease;

    &:hover {
      opacity: 0.7;
    }
  }

  &__action {
    margin: 63px auto 0;
    width: 344px;
    max-width: 100%;

    @include mq('sm') {
      width: 100%;
    }
  }
}
</style>
